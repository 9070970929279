import api from "@/assets/js/api";

export const GET_ROLES = "get/roles";

const roleModule = {
    actions: {
        async [GET_ROLES]() {
            try {
                const response = await api.main.get({
                    url: "/api/roles",
                });
                const data = response.data.data;
                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, []);
            }
        },
    },
};

export default roleModule;
