import api from "@/assets/js/api";

export const GET_DESIGNATIONS = "get/designations";

const designationModule = {
    actions: {
        async [GET_DESIGNATIONS]() {
            try {
                const response = await api.main.get({
                    url: "/api/designations",
                });
                const data = response.data.data;
                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, []);
            }
        },
    },
};

export default designationModule;
