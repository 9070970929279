'use strict';

const global = {
  data() {
    return {
      confirmDialog: { show: false, loading: false, title: '', message: '', action: '', data: {}, callback: undefined }
    };
  },
  filters: {},
  methods: {
    getParent(name) {
      let p = this.$parent;
      while (typeof p !== 'undefined') {
        if (p.$options.name === name) {
          return p;
        } else {
          p = p.$parent;
        }
      }
      return false;
    },
    confirmAction({ title, message, action, data, callback }) {
      this.confirmDialog = { ...{ show: false, loading: false, title: '', message: '', action: '', data: {}, callback: undefined }, show: true, title, message, action, data, callback };
    }
  }
};

export default global;