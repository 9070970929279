import Vue from "vue";
import Vuex from "vuex";

import authentication from "./modules/authentication";
import prayer_requests from "./modules/engage/prayer_requests";
import events from "./modules/engage/events";

import profile from "@/store/modules/profile";
import selection from "./modules/selection";
import learn from "@/store/modules/learn";
import watch from "@/store/modules/watch";
import settings from "@/store/modules/settings";
import user from "@/store/modules/user";
import designation from "@/store/modules/designation";
import role from "@/store/modules/role";
import group from "@/store/modules/group";
import request from "@/store/modules/request";
import session from "@/store/modules/session";
import announcement from "@/store/modules/announcement";
import event from "@/store/modules/event";
import prayerRequest from "@/store/modules/prayerRequest";
import dailyDevotion from "@/store/modules/dailyDevotion";
import report from "@/store/modules/report";

Vue.use(Vuex);

const vue = new Vue({
    name: "store",
});

const store = new Vuex.Store({
    strict: true,
    actions: {
        clearState(context) {
            context.commit("authentication/CLEAR_STATE");
            context.commit("users/CLEAR_STATE");
        },
        error(context, payload) {
            // console.log(payload.response);

            // 200 = Success
            // 400 = Bad Request
            // 401 = Authorization Failed
            // 403 = Access Denied
            // 404 = Entity Not Found
            // 419 = Token Expired
            // 422 = Parameter Error
            // 500 = Server Error

            if (payload.response && payload.response.status === 401) {
                vue.$eventBus.$emit("clear-data"); // refactor this code
            }
        },
    },
    modules: {
        authentication, // refactor this code
        prayer_requests, // refactor this code
        events, // refactor this code
        selection,
        learn,
        watch,
        settings,
        user,
        designation,
        role,
        group,
        profile,
        request,
        session,
        announcement,
        event,
        prayerRequest,
        dailyDevotion,
        report,
    },
});

export default store;
