"use strict";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
Vue.$cookies.config("7d", "", "", true, "Strict");

// Cookies Management
const cookies = {
    setValue(key, value) {
        const item = {
            value: value,
            key: key,
        };
        // Encode To Base64
        localStorage.setItem(key, JSON.stringify(item));
    },

    getValue(key) {
        let item = localStorage.getItem(key);
        if (!item) {
            return null;
        }

        // Decode To Base64 and JSON parse
        item = JSON.parse(item);
        return item.value;
    },
    removeValue(key) {
        localStorage.removeItem(key);
    },
};

// console.log("Prototype cookies", cookies);

export default cookies;
