import api from "@/assets/js/api";

export const CREATE_REQUEST = "request/actions/create";
export const UPDATE_REQUEST = "request/actions/update";
export const GET_REQUESTS = "request/actions/get";

const requestModule = {
    actions: {
        async [GET_REQUESTS](
            _,
            { page, perPage, sortBy, dateFrom, dateTo, type, status }
        ) {
            try {
                const route = `${api.baseURL}/api/user-requests`;
                const url = new URL(route);
                const params = new URLSearchParams(url.search);
                params.set("page", page);
                params.set("per_page", perPage || 10);
                if (sortBy) params.set("sort_by", sortBy);
                if (type) params.set("request_type", type);
                if (status) params.set("status", status);
                if (dateFrom && dateTo) {
                    params.set("from", dateFrom);
                    params.set("to", dateTo);
                }
                const response = await api.main.get({
                    url: `/api/user-requests?${params}`,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [CREATE_REQUEST](_, payload) {
            try {
                const response = await api.main.post({
                    url: "/api/user-requests",
                    data: Object.assign({}, payload),
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [UPDATE_REQUEST](_, payload) {
            try {
                payload._method = "PUT";
                const requestID = payload.requestID;
                delete payload.requestID;
                const response = await api.main.post({
                    url: `/api/user-requests/${requestID}`,
                    data: Object.assign({}, payload),
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },
    },
};

export default requestModule;
