import api from "@/assets/js/api";

export const WATCH_CREATE_VIDEO = "watch/actions/create/video";
export const WATCH_UPDATE_VIDEO = "watch/actions/update/video";
export const WATCH_GET_VIDEOS = "watch/actions/get/videos";
export const WATCH_DELETE_VIDEO = "watch/actions/delete/video";

const toFormData = (
    {
        video_category_id,
        title,
        description,
        youtube_link,
        schedule_at,
        speaker,
        scripture,
        featured_on_front_page,
        live_on_watch_page,
        live_from,
        live_to,
        exclude_to_listing,
        bulletin_url,
        study_guide_url,
        language_id,
        post_expiration,
        expiration_date,
        featured_image,
        home_thumbnail,
        small_thumbnail_alternate,
        video_series,
        video_topics,
    },
    operation
) => {
    const formData = new FormData();
    if (video_category_id) formData.set("video_category_id", video_category_id);
    if (title) formData.set("title", title);
    if (description) formData.set("description", description);
    if (youtube_link) formData.set("youtube_link", youtube_link);
    if (schedule_at) formData.set("schedule_at", schedule_at);
    if (speaker) formData.set("speaker", speaker);
    if (scripture) formData.set("scripture", scripture);
    if (featured_on_front_page) formData.set("featured_on_front_page", featured_on_front_page ? 1 : 0);
    if (live_on_watch_page) formData.set("live_on_watch_page", live_on_watch_page);
    if (live_from) formData.set("live_from", live_from);
    if (live_to) formData.set("live_to", live_to);
    if (exclude_to_listing) formData.set("exclude_to_listing", exclude_to_listing ? 1 : 0);
    if (bulletin_url) formData.set("bulletin_url", bulletin_url);
    if (study_guide_url) formData.set("study_guide_url", study_guide_url);
    if (language_id) formData.set("language_id", language_id);
    if (post_expiration) formData.set("post_expiration", post_expiration ? 1 : 0);
    if (expiration_date) formData.set("expiration_date", expiration_date);
    if (featured_image) formData.set("featured_image", featured_image);
    if (home_thumbnail) formData.set("home_thumbnail", home_thumbnail);
    if (small_thumbnail_alternate) formData.set("small_thumbnail_alternate", small_thumbnail_alternate);
    video_series.forEach((video, index) => {
        if (typeof video === "object") {
            formData.append(`video_series[${index}][id]`, video.id);
            formData.append(`video_series[${index}][name]`, video.name);
        } else if (typeof video === "string") {
            formData.append(`video_series[${index}][name]`, video);
        }
    });
    video_topics.forEach((topic, index) => {
        if (typeof topic === "object") {
            formData.append(`video_topics[${index}][id]`, topic.id);
            formData.append(`video_topics[${index}][name]`, topic.name);
        } else if (typeof topic === "string") {
            formData.append(`video_topics[${index}][name]`, topic);
        }
    });
    if (operation === "update") formData.set("_method", "PUT");

    return formData;
};

const watchModule = {
    actions: {
        async [WATCH_CREATE_VIDEO](_, payload) {
            try {
                const formData = toFormData(payload);
                const response = await api.main.post({
                    url: "/api/videos",
                    data: formData,
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [WATCH_UPDATE_VIDEO](_, payload) {
            try {
                const formData = toFormData(payload, "update");
                const videoID = payload.id;
                const response = await api.main.post({
                    url: `/api/videos/${videoID}`,
                    data: formData,
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [WATCH_GET_VIDEOS](
            _,
            {
                page,
                perPage,
                uploadSort,
                playlists,
                videoSeries,
                dateFrom,
                dateTo,
            }
        ) {
            try {
                const route = `${api.baseURL}/api/videos`;
                const url = new URL(route);
                const params = new URLSearchParams(url.search);
                params.set("page", page);
                params.set("per_page", perPage);
                params.set("sort_by_schedule", uploadSort);
                playlists.forEach((item, index) =>
                    params.append(`playlists[${index}]`, item)
                );
                videoSeries.forEach((item, index) =>
                    params.append(`video_series[${index}]`, item)
                );
                if (dateFrom) {
                    params.set("from", dateFrom);
                }
                if (dateTo) {
                    params.set("to", dateTo);
                }
                const response = await api.main.get({
                    url: `/api/videos?${params}`,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [WATCH_DELETE_VIDEO](_, videoID) {
            try {
                const response = await api.main.delete({
                    url: `/api/videos/${videoID}`,
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },
    },
};

export default watchModule;
