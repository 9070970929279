import api from "@/assets/js/api";

export const GET_USERS = "user/get";
export const GET_USER = "user/get-one";
export const CREATE_USER = "user/create";
export const UPDATE_USER = "user/update";
export const DELETE_USER = "user/delete";
export const UPDATE_USER_MILESTONE = "user/update/milestone";
export const UPDATE_USER_PASSWORD = "user/update/password";

const userModule = {
    actions: {
        async [GET_USERS](
            _,
            {
                page,
                perPage,
                search,
                sortBy,
                accessControlUsers,
                membership,
                designation,
                role,
                dateFrom,
                dateTo,
                milestone,
            }
        ) {
            try {
                const route = `${api.baseURL}/api/users`;
                const url = new URL(route);
                const params = new URLSearchParams(url.search);
                params.set("page", page);
                params.set("per_page", perPage || 10);
                if (accessControlUsers)
                    params.set("access_control_users", accessControlUsers);
                if (sortBy) params.set("sort_by", sortBy);
                if (search) params.set("search", search);
                if (membership) params.set("membership", membership);
                if (designation) params.set("designation", designation);
                if (role) params.set("user_type", role);
                if (dateFrom && dateTo) {
                    params.set("from", dateFrom);
                    params.set("to", dateTo);
                }
                if (milestone) params.set("milestone", milestone);
                const response = await api.main.get({
                    url: `/api/users?${params}`,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [GET_USER](_, userID) {
            try {
                const response = await api.main.get({
                    url: `/api/users/${userID}`,
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [CREATE_USER](_, payload) {
            try {
                const response = await api.main.post({
                    url: "/api/users",
                    data: Object.assign({}, payload),
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [UPDATE_USER](_, payload) {
            try {
                payload._method = "PUT";
                const userID = payload.userID;
                delete payload.userID;
                const response = await api.main.post({
                    url: `/api/users/${userID}`,
                    data: Object.assign({}, payload),
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [DELETE_USER](_, userID) {
            try {
                const response = await api.main.delete({
                    url: `/api/users/${userID}`,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [UPDATE_USER_MILESTONE](_, payload) {
            try {
                payload._method = "PUT";
                const userID = payload.userID;
                delete payload.userID;
                const response = await api.main.post({
                    url: `/api/user-milestones/${userID}`,
                    data: Object.assign({}, payload),
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [UPDATE_USER_PASSWORD](_, payload) {
            payload._method = "PUT";
            console.log(payload);
            delete payload.userID;
            const response = await api.main.put({
                url: `/api/user-password/change`,
                data: Object.assign({}, payload),
            });
            const data = response.data;
            return api.formatResponse(true, data);
        },
    },
};

export default userModule;
