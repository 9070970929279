import api from "@/assets/js/api";

export const GET_PROFILE_MEMBERSHIPS = "get/profile/memberships";
const SET_PROFILE_MEMBERSHIPS_DATA = "set/profile/memberships/data";
export const GET_PROFILE_MILESTONES = "get/profile/milestones";
const SET_PROFILE_MILESTONES_DATA = "set/profile/milestones/data";
export const GET_PROFILE_DESIGNATIONS = "get/profile/designations";
const SET_PROFILE_DESIGNATIONS_DATA = "set/profile/designations/data";
export const GET_PROFILE_ROLES = "get/profile/roles";
const SET_PROFILE_ROLES_DATA = "set/profile/roles/data";
export const GET_PROFILE_RELATIONSHIPS = "get/profile/relationships";
const SET_PROFILE_RELATIONSHIPS_DATA = "set/profile/relationships/data";
export const GET_PROFILE_REQUEST_TYPES = "get/profile/request/types";
const SET_PROFILE_REQUEST_TYPES_DATA = "set/profile/request/types/data";
export const GET_PROFILE = "get/profile/get-profile";

const requestStatuses = ["New", "Pending", "Completed"];

const profileModule = {
    state: {
        memberships: [],
        milestones: [],
        designations: [],
        roles: [],
        relationships: [],
        requestStatuses,
        requestTypes: [],
    },

    mutations: {
        [SET_PROFILE_MEMBERSHIPS_DATA](state, payload) {
            state.memberships = [...payload];
        },

        [SET_PROFILE_MILESTONES_DATA](state, payload) {
            state.milestones = [...payload];
        },

        [SET_PROFILE_DESIGNATIONS_DATA](state, payload) {
            state.designations = [...payload];
        },

        [SET_PROFILE_ROLES_DATA](state, payload) {
            state.roles = [...payload];
        },

        [SET_PROFILE_RELATIONSHIPS_DATA](state, payload) {
            state.relationships = [...payload];
        },

        [SET_PROFILE_REQUEST_TYPES_DATA](state, payload) {
            state.requestTypes = [...payload];
        },
    },

    actions: {
        async [GET_PROFILE_MEMBERSHIPS]({ commit }) {
            try {
                const response = await api.main.get({
                    url: "/api/memberships",
                });
                const data = response.data.data;
                commit(SET_PROFILE_MEMBERSHIPS_DATA, data);
                return api.formatResponse(true, data);
            } catch (error) {
                commit(SET_PROFILE_MEMBERSHIPS_DATA, []);
                return api.formatResponse(false, []);
            }
        },

        async [GET_PROFILE_MILESTONES]({ commit }) {
            try {
                const response = await api.main.get({
                    url: "/api/milestones",
                });
                const data = response.data.data;
                commit(SET_PROFILE_MILESTONES_DATA, data);
                return api.formatResponse(true, data);
            } catch (error) {
                commit(SET_PROFILE_MILESTONES_DATA, []);
                return api.formatResponse(false, []);
            }
        },

        async [GET_PROFILE_DESIGNATIONS]({ commit }) {
            try {
                const response = await api.main.get({
                    url: "/api/designations",
                });
                const data = response.data.data;
                commit(SET_PROFILE_DESIGNATIONS_DATA, data);
                return api.formatResponse(true, data);
            } catch (error) {
                commit(SET_PROFILE_DESIGNATIONS_DATA, []);
                return api.formatResponse(false, []);
            }
        },

        async [GET_PROFILE_ROLES]({ commit }) {
            try {
                const response = await api.main.get({
                    url: "/api/roles",
                });
                const data = response.data.data;
                commit(SET_PROFILE_ROLES_DATA, data);
                return api.formatResponse(true, data);
            } catch (error) {
                commit(SET_PROFILE_ROLES_DATA, []);
                return api.formatResponse(false, []);
            }
        },

        async [GET_PROFILE_RELATIONSHIPS]({ commit }) {
            try {
                const response = await api.main.get({
                    url: "/api/relationships",
                });
                const data = response.data.data;
                commit(SET_PROFILE_RELATIONSHIPS_DATA, data);
                return api.formatResponse(true, data);
            } catch (error) {
                commit(SET_PROFILE_RELATIONSHIPS_DATA, []);
                return api.formatResponse(false, []);
            }
        },

        async [GET_PROFILE_REQUEST_TYPES]({ commit }) {
            try {
                const response = await api.main.get({
                    url: "/api/request-types",
                });
                const data = response.data.data;
                commit(SET_PROFILE_REQUEST_TYPES_DATA, data);
                return api.formatResponse(true, data);
            } catch (error) {
                commit(SET_PROFILE_REQUEST_TYPES_DATA, []);
                return api.formatResponse(false, []);
            }
        },

        async [GET_PROFILE](_, userID) {
            try {
                console.log("PROFILE")
                const response = await api.main.get({
                    url: `/api/users/${userID}`,
                });
                const data = response.data;
                console.log("RESPONSE", data)
                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, []);
            }
        },
    },
};

export default profileModule;
