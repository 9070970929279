import Vue from "vue";
import VueRouter from "vue-router";

const MirrorView = () => import("@/components/Base/MirrorView");
const AuthView = () => import("@/views/AuthView");
const Login = () => import("@/components/AuthView/Login");

const MainView = () => import("@/views/MainView");
const Watch = () => import("@/components/MainView/Watch/index");
const Learn = () => import("@/components/MainView/Learn/index");

const Sessions = () => import("@/components/MainView/Engage/Sessions/index");
const PrayerRequests = () =>
    import("@/components/MainView/Engage/PrayerRequests/index");
const DailyDevotions = () =>
    import("@/components/MainView/Engage/DailyDevotions/index");
const EventCalendar = () =>
    import("@/components/MainView/Engage/EventCalendar/index");

const Users = () => import("@/components/MainView/Ministry/Users/index");
const Groups = () => import("@/components/MainView/Ministry/Groups/index");
const Requests = () => import("@/components/MainView/Ministry/Requests/index");
const Announcement = () =>
    import("@/components/MainView/Ministry/Announcement");

const AccessControl = () =>
    import("@/components/MainView/Settings/AccessControl");
const Banner = () => import("@/components/MainView/Settings/Banners/index");
const Profile = () => import("@/components/MainView/Profile/index");
const Unauthorized = () => import("@/components/Core/Unauthorized");

const vue = new Vue({
    name: "router",
});

Vue.use(VueRouter);

const routes = [
    {
        path: "/auth",
        name: "auth",
        redirect: { name: "login" },
        component: AuthView,
        meta: { requiresAuthentication: false },
        children: [
            {
                path: "login",
                name: "login",
                component: Login,
                meta: { requiresAuthentication: false },
            },
        ],
    },

    {
        path: "/",
        name: "main-view",
        redirect: { name: "dashboard" },
        component: MainView,
        meta: { requiresAuthentication: true },
        children: [
            {
                path: "dashboard",
                name: "dashboard",
                component: MirrorView,
                redirect: { name: "dashboard-users" },
                meta: {
                    requiresAuthentication: true,
                    accessControl: "portal-dashboard",
                },
                children: [
                    {
                        path: "users",
                        name: "dashboard",
                        component: () =>
                            import(
                                "@/components/MainView/Dashboard/Users/index"
                            ),
                        meta: {
                            requiresAuthentication: true,
                            accessControl: "portal-dashboard",
                        },
                    },

                    {
                        path: "app-usage",
                        name: "dashboard-app-usage",
                        component: () =>
                            import(
                                "@/components/MainView/Dashboard/AppUsage/index"
                            ),
                        meta: {
                            requiresAuthentication: true,
                            accessControl: "portal-dashboard",
                        },
                    },
                    {
                        path: "session",
                        name: "dashboard-session",
                        component: () =>
                            import(
                                "@/components/MainView/Dashboard/Session/index"
                            ),
                        meta: {
                            requiresAuthentication: true,
                            accessControl: "portal-dashboard",
                        },
                    },
                ],
            },

            {
                path: "watch",
                name: "watch",
                component: Watch,
                meta: {
                    requiresAuthentication: true,
                    accessControl: "portal-watch",
                },
            },

            {
                path: "learn",
                name: "learn",
                component: Learn,
                meta: {
                    requiresAuthentication: true,
                    accessControl: "portal-learn",
                },
            },

            {
                path: "profile",
                name: "profile",
                component: Profile,
                meta: {
                    requiresAuthentication: true,
                    accessControl: "portal-profile-management",
                },
            },

            {
                path: "engage",
                name: "engage",
                component: MirrorView,
                redirect: { name: "sessions" },
                meta: {
                    requiresAuthentication: true,
                    accessControl: "portal-engage",
                },
                children: [
                    {
                        path: "sessions",
                        name: "sessions",
                        component: Sessions,
                        meta: {
                            requiresAuthentication: true,
                            accessControl: "portal-engage-sessions",
                        },
                    },
                    {
                        path: "prayer-requests",
                        name: "prayer-requests",
                        component: PrayerRequests,
                        meta: {
                            requiresAuthentication: true,
                            accessControl: "portal-engage-prayer-requests",
                        },
                    },
                    {
                        path: "daily-devotions",
                        name: "daily-devotions",
                        component: DailyDevotions,
                        meta: {
                            requiresAuthentication: true,
                            accessControl: "portal-engage-daily-devotions",
                        },
                    },
                    {
                        path: "events-calendar",
                        name: "events-calendar",
                        component: EventCalendar,
                        meta: {
                            requiresAuthentication: true,
                            accessControl: "portal-engage-events-calendar",
                        },
                    },
                ],
            },

            {
                path: "ministry",
                name: "ministry",
                component: MirrorView,
                redirect: { name: "users" },
                meta: { requiresAuthentication: true },
                children: [
                    {
                        path: "users",
                        name: "users",
                        component: Users,
                        meta: {
                            requiresAuthentication: true,
                            accessControl: "portal-profile-management-users",
                        },
                    },
                    {
                        path: "groups",
                        name: "groups",
                        component: Groups,
                        meta: {
                            requiresAuthentication: true,
                            accessControl: "portal-profile-management-groups",
                        },
                    },
                    {
                        path: "requests",
                        name: "requests",
                        component: Requests,
                        meta: {
                            requiresAuthentication: true,
                            accessControl: "portal-profile-management-requests",
                        },
                    },
                    {
                        path: "announcements",
                        name: "announcements",
                        component: Announcement,
                        meta: {
                            requiresAuthentication: true,
                            accessControl: "portal-profile-management-requests",
                        },
                    },
                ],
            },

            {
                path: "settings",
                name: "settings",
                component: MirrorView,
                redirect: { name: "access-control" },
                meta: { requiresAuthentication: true },
                children: [
                    {
                        path: "access-control",
                        name: "access-control",
                        component: AccessControl,
                        meta: {
                            requiresAuthentication: true,
                            accessControl: "portal-settings",
                        },
                    },
                    {
                        path: "banner",
                        name: "banner",
                        component: Banner,
                        meta: {
                            requiresAuthentication: true,
                            accessControl: "portal-settings",
                        },
                    },
                ],
            },
            // {
            //   path: 'users',
            //   name: 'users',
            //   component: Users,
            //   meta: { requiresAuthentication: true }
            // }
            {
                path: "unauthorized",
                name: "unauthorized",
                component: Unauthorized,
                meta: {
                    requiresAuthentication: true,
                },
            },
        ],
    },
    // {
    //   path: '/template',
    //   name: 'template',
    //   // route level code-splitting
    //   // this generates a separate chunk (template.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "template" */ '@/views/template')
    // }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    vue.$api.main.cancelCurrentApiCall();

    const token = vue.$cookies.getValue("token");

    if (to.meta.requiresAuthentication) {
        const pageAccessControl = to.meta.accessControl || null;
        const user = vue.$cookies.getValue("user");
        const accessControls = user
            ? user.access_controls
                  .filter((control) => control.have_access)
                  .map((control) => control.slug)
            : [];

        if (pageAccessControl && accessControls.length > 0) {
            const haveAccessControl =
                accessControls.includes(pageAccessControl);
            if (!haveAccessControl) {
                next({ name: "unauthorized" });
            }
        }

        if (token) {
            if (!vue.$api.main.getAuthorization()) {
                vue.$api.main.setAuthorization(token);
            } // Decode Base64
            next();
        } else {
            vue.$cookies.removeValue("token");
            vue.$api.main.setAuthorization(undefined);
            next({
                name: "login",
                query: {
                    redirect: to.name !== "dashboard" ? to.name : undefined,
                },
            });
        }
    } else {
        if (to.matched.length === 0) {
            next();
        } else if (token) {
            if (!vue.$api.main.getAuthorization()) {
                vue.$api.main.setAuthorization(token);
            } // Decode Base64
            next({ name: "main-view" });
        } else {
            vue.$cookies.removeValue("token");
            next();
        }
    }
});

export default router;
