'use strict';

import users from '@/assets/js/api/modules/users';

export default {
  namespaced: true,
  state: {
    events_list: undefined,
    event_details: undefined
  },
  getters: {
    getEventsList: state => state.events_list,
    getEventsDetails: state => state.event_details,
  },
  mutations: {
    SET_EVENTS_LIST(state, payload) {
        state.events_list = payload;
    },
    SET_EVENT_DETAILS(state, payload) {
        state.request_details = payload;
    },
    CLEAR_STATE(state) {
        state.events_list = undefined;
        state.event_details = undefined;
    }
  },
  actions: {
    fetchEvents(context, payload) {
      const { config } = payload || {};
      return new Promise((resolve, reject) => {
        users.getData({ to: '/api/events', config }).then(
          (response) => {
            // console.log(response);
            context.commit('SET_EVENTS_LIST', response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_EVENTS_LIST', undefined);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    findEvent(context, payload) {
      const { id, config } = payload || {};
      return new Promise((resolve, reject) => {
        users.getData({ to: `/api/events/${id}`, config }).then(
          (response) => {
            // console.log(response);
            context.commit('SET_EVENT_DETAILS', response.data);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.commit('SET_REQUEST_DETAILS', undefined);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    addEvent(context, payload) {
      const { data, config } = payload || {};
      return new Promise((resolve, reject) => {
        users.postData({ to: '/api/events', data, config }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    setEvent(context, payload) {
      const { id, data, config } = payload || {};
      return new Promise((resolve, reject) => {
        users.postData({ to: `/api/events/${id}`, data, config }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    },
    deleteEvent(context, payload) {
      const { config } = payload || {};
      return new Promise((resolve, reject) => {
        users.deleteData({ to: '/api/events', config }).then(
          (response) => {
            // console.log(response);
            resolve(response);
          }
        ).catch(
          (error) => {
            // console.log(error);
            context.dispatch('error', error, { root: true });
            reject(error);
          }
        );
      });
    }
  }
};