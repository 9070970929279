import api from "@/assets/js/api";

export const CREATE_ANNOUNCEMENT = "announcement/create";
export const GET_ANNOUNCEMENTS = "announcement/get";
export const UPDATE_ANNOUNCEMENT = "announcement/update";
export const DELETE_ANNOUNCEMENT = "announcement/delete";

const announcementModule = {
    actions: {
        async [CREATE_ANNOUNCEMENT](_, payload) {
            try {
                const response = await api.main.post({
                    url: "/api/announcements",
                    data: Object.assign({}, payload),
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [GET_ANNOUNCEMENTS](_, { page, perPage }) {
            try {
                const route = `${api.baseURL}/api/users`;
                const url = new URL(route);
                const params = new URLSearchParams(url.search);
                params.set("page", page);
                params.set("per_page", perPage || 10);
                const response = await api.main.get({
                    url: `/api/announcements?${params}`,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [UPDATE_ANNOUNCEMENT](_, payload) {
            try {
                const announcementID = payload.announcementID;
                delete payload.announcementID;
                payload._method = "PUT";
                const response = await api.main.post({
                    url: `/api/announcements/${announcementID}`,
                    data: payload,
                });
                const data = response.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },

        async [DELETE_ANNOUNCEMENT](_, announcementID) {
            try {
                const response = await api.main.delete({
                    url: `/api/announcements/${announcementID}`,
                });
                const data = response.data.data;

                return api.formatResponse(true, data);
            } catch (error) {
                return api.formatResponse(false, null, error);
            }
        },
    },
};

export default announcementModule;
